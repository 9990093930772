import React from 'react';
import './Footer.css';

function Footer() {

  return (

    <footer>
      <h5> Created By TIEGRUN </h5>
    </footer>
  )
}

export default Footer